<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-card-body>
      <b-row>
        <template v-if="isAdmin">
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('buyer') }}</label>
            <LegalSelect v-model="localLegals" />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('buyerCountry') }}</label>
            <CountrySelect v-model="localCountries" />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('verifiedStatus') }}</label>
            <multiselect
              v-model="localVerified"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              label="label"
              track-by="value"
              :options="verifiedOptions"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('publishedStatus') }}</label>
            <multiselect
              v-model="localStatuses"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              label="label"
              track-by="value"
              :options="statusesOptions"
            />
          </b-col>
        </template>
        <template v-else>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('buyerCountry') }}</label>
            <CountrySelect v-model="localCountries" />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('status') }}</label>
            <multiselect
              v-model="localStatuses"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              label="label"
              track-by="value"
              :options="statusesOptions"
            />
          </b-col>
        </template>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCol, BRow,
} from 'bootstrap-vue';
import CountrySelect from '@/views/apps/components/CountrySelect.vue';
import { verifiedOptions } from '@/constants/verifiedOptions.js';
import LegalSelect from '@/views/apps/components/LegalSelect.vue';

export default {
  name: 'TradingRequestFilter',
  components: {
    LegalSelect,
    CountrySelect,
    BCard,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    legals: {
      type: Array,
    },
    countries: {
      type: Array,
    },
    statuses: {
      type: Object,
    },
    verified: {
      type: Object,
    },
  },
  data() {
    return {
      statusesOptions: [
        {
          label: 'Published',
          value: '1',
        },
        {
          label: 'Not published',
          value: '0',
        },
      ],
      verifiedOptions,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
    localLegals: {
      get() {
        return this.legals;
      },
      set(val) {
        this.$emit('update:legals', val);
      },
    },
    localCountries: {
      get() {
        return this.countries;
      },
      set(val) {
        this.$emit('update:countries', val);
      },
    },
    localStatuses: {
      get() {
        return this.statuses;
      },
      set(val) {
        this.$emit('update:statuses', val);
      },
    },
    localVerified: {
      get() {
        return this.verified;
      },
      set(val) {
        this.$emit('update:verified', val);
      },
    },
  },
};
</script>
