import { render, staticRenderFns } from "./OpenRequestList.vue?vue&type=template&id=181bb981&scoped=true&"
import script from "./OpenRequestList.vue?vue&type=script&lang=js&"
export * from "./OpenRequestList.vue?vue&type=script&lang=js&"
import style0 from "./OpenRequestList.vue?vue&type=style&index=0&id=181bb981&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "181bb981",
  null
  
)

export default component.exports