import { ref } from '@vue/composition-api';

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    term: '',
    offersModeDefined: 'all',
    priceRange: [0, 100],
    categories: [],
    brands: [],
    page: 1,
    perPage: 9,
  });

  const filterOptions = {
    offersModeDefined: [
      { text: 'All', value: 'all' },
      { text: 'Express', value: 'express' },
      { text: 'Normal', value: 'normal' },
    ],
    categories: [
      { text: 'Category #1', value: '1' },
      { text: 'Category #2', value: '2' },
      { text: 'Category #3', value: '3' },
      { text: 'Category #4', value: '4' },
      { text: 'Category #5', value: '5' },
      { text: 'Category #6', value: '6' },
      { text: 'Category #7', value: '7' },
      { text: 'Category #8', value: '8' },
      { text: 'Category #9', value: '9' },
    ],
  };

  // Sorting
  const sortBy = ref({ text: 'Featured', value: 'featured' });
  const sortByOptions = [
    { text: 'Featured', value: 'featured' },
    { text: 'Lowest', value: 'price-asc' },
    { text: 'Highest', value: 'price-desc' },
  ];

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  };
};

export const useShopUi = () => {
  const itemView = 'list-view';
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ];

  return {
    itemView,
    itemViewOptions,
  };
};
