export const verifiedOptions = [
  {
    label: 'Verified',
    value: '1',
  },
  {
    label: 'Not verified',
    value: '0',
  },
];
